import React, { useEffect, useState } from "react";

const FUNCTIONS = ["Developer", "Team Lead", "Consultant"];

function HeaderNameFunction() {
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState(0);
  const [currentFunction, setCurrentFunction] = useState(
    FUNCTIONS[currentFunctionIndex]
  );
  const [className, setClassname] = useState("fade-in");

  useEffect(() => {
    setTimeout(() => {
      setClassname("fade-out");
    }, 4000);

    setTimeout(() => {
      let nextFunctionIndex = currentFunctionIndex + 1;
      if (nextFunctionIndex >= FUNCTIONS.length) {
        nextFunctionIndex = 0;
      }

      setCurrentFunctionIndex(nextFunctionIndex);
      setCurrentFunction(FUNCTIONS[nextFunctionIndex]);
      setClassname("fade-in");
    }, 5000);
  }, [currentFunction]);

  return (
    <div className={`header-name-function ${className}`}>{currentFunction}</div>
  );
}

export default HeaderNameFunction;
