import React from "react";

import HeaderNameFunction from "./HeaderNameFunction";

import "./styles.scss";

import linkedinIcon from "../../assets/icons/linkedin.svg";
import githubIcon from "../../assets/icons/github.svg";

function Header() {
  return (
    <header className="App-header">
      <div className="header-content">
        <div className="header-name">
          <div className="header-name-name">Pim Hopmans</div>
          <div className="header-name-divider">•</div>
          <HeaderNameFunction />
        </div>

        <div className="socials">
          <a
            className="social"
            href="https://www.linkedin.com/in/pim-hopmans-b758a7171/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
          <a
            className="social"
            href="https://github.com/pimhopmans"
            target="_blank"
            rel="noreferrer"
          >
            <img src={githubIcon} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
