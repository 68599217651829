import React from "react";

import "./styles.scss";

import experience from "../../assets/data/experience.json";

import experienceIcon from "../../assets/icons/experience.svg";

function Experience() {
  return (
    <div className="cv-experience-wrapper">
      <div className="cv-section cv-experience">
        <div className="cv-title-wrapper">
          <img src={experienceIcon} alt="Experience" />
          <div className="cv-title">Experience</div>
        </div>

        {experience.map((item) => (
          <div key={`${item.function}-${item.company}`} className="cv-item">
            <div className="cv-item-metadata">
              <div className="cv-item-function">{item.function}</div>
              <div className="cv-item-title">{item.company}</div>
              <div className="cv-item-date">{item.date}</div>
              <div className="cv-item-location">{item.location}</div>
            </div>

            <div className="cv-item-text">{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experience;
