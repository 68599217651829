import React from "react";

import "./styles.scss";

import education from "../../assets/data/education.json";

import educationIcon from "../../assets/icons/education.svg";

function Education() {
  return (
    <div className="cv-education-wrapper">
      <div className="cv-section cv-education">
        <div className="cv-title-wrapper">
          <img src={educationIcon} alt="Education" />
          <div className="cv-title">Education</div>
        </div>

        {education.map((item) => (
          <div
            key={`${item.programme}-${item.institution}`}
            className="cv-item"
          >
            <div className="cv-item-programme">{item.programme}</div>
            <div className="cv-item-institution">{item.institution}</div>
            <div className="cv-item-date">{item.date}</div>
            <div className="cv-item-location">{item.location}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Education;
