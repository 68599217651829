import React from "react";

import "./styles.scss";

import profile from "../../assets/images/profile_2.jpg";

function Profile() {
  return (
    <div className="profile-wrapper">
      <div className="profile">
        <div className="avatar">
          <img src={profile} alt="Avatar" />
        </div>
        <div className="profile-name">Pim Hopmans</div>
        <a className="profile-email" href="mailto: info@pimhopmans.nl">
          info@pimhopmans.nl
        </a>
        <div className="profile-location">Utrecht, The Netherlands</div>
      </div>
    </div>
  );
}

export default Profile;
