import React from "react";

import SkillScore from "./SkillScore";

import "./styles.scss";

import skills from "../../assets/data/skills.json";

import skillsIcon from "../../assets/icons/skills.svg";

function Skills() {
  return (
    <div className="skills-wrapper">
      <div className="skills-title-wrapper">
        <img src={skillsIcon} alt="Skills" />
        <div className="skills-title">Skills</div>
      </div>

      <div className="skills">
        {skills.map((skill) => (
          <div className="skill">
            <div className="skill-name">{skill.name}</div>
            <SkillScore score={skill.score} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
