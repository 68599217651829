import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

import blueStar from "../../../assets/icons/score-blue.svg";
import blackStar from "../../../assets/icons/score-black.svg";

// eslint-disable-next-line no-unused-vars
function SkillScore({ score }) {
  const blueStarCount = score;
  const blueStars = [...Array(blueStarCount).keys()];

  const blackStarCount = 5 - score;
  const blackStars = [...Array(blackStarCount).keys()];

  return (
    <div className="skill-score">
      {blueStars.map(() => (
        <img src={blueStar} alt="ja" />
      ))}
      {blackStars.map(() => (
        <img src={blackStar} alt="nee" />
      ))}
    </div>
  );
}

SkillScore.propTypes = {
  score: PropTypes.number.isRequired,
};

export default SkillScore;
