import React from "react";

import Header from "./components/Header";
import Profile from "./components/Profile";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Education from "./components/Education";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header />

      <div className="main">
        <div className="main-content">
          <div className="meta">
            <Profile />
            <Skills />
          </div>

          <div className="cv">
            <Experience />
            <Education />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
